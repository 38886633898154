export function getComprovantHTML(id, name, address, shipping) {
  return html(id, name, address, shipping)
}


const html = (id, name, address, shipping) => `<div style="width: 100%; max-width: 700px;
    padding-top: 12px;
    margin-right: auto;">
    <div style="width: 100%; display: flex; align-items: center !important; font-family: Rubik, sans-serif !important;">
    <div style="flex: 1 1 0%;  font-family: Rubik, sans-serif !important;">
    <span class="font-weight-bold" style="
  font-size:  36px !important;
  font-weight: bold !important;
  font-family: Rubik, sans-serif !important;
  line-height: 1.75rem;
  letter-spacing: 0.009375em !important; color: rgb(70, 110, 181) !important">examenselectivitat</span></div>
  <div style="flex-grow: 1 !important;  font-family: Rubik, sans-serif !important;"></div>
<div style="margin-left: 24px; align-items: flex-end !important;">
  <span style="font-weight: 500 !important;
  align-items: flex-end !important;
   font-family: Rubik, sans-serif !important;
  text-align: end !important;color: black !important;" class="align-end font-weight-medium">Confirmació de la comanda</span>
  <br>
  <div style="padding: 16px !important;  font-family: Rubik, sans-serif !important; border-radius: 8px !important;margin-top: 4px !important; background-color: #eeeeee !important;"
  class="pa-4 d-flex rounded-lg mt-1 v-card v-card--flat v-sheet theme--light grey lighten-3">
  <span
    style="font-size: 0.75rem !important;
  font-weight: 400;
   font-family: Rubik, sans-serif !important;
  letter-spacing: 0.0333333333em !important;
  line-height: 1.25rem; color: black !important;"
   class="caption text-end"> Número de comanda </span><span data-v-2595c7a6="" style="font-size: 0.75rem !important;
  font-weight: 500;
  letter-spacing: 0.0333333333em !important;
  line-height: 1.25rem;
  font-family: 'Roboto', sans-serif !important;  text-align: end !important; color: #1976D2 !important;"

    class="caption text-end blue--text text--darken-2 font-weight-medium">${id}</span></div></div></div>
    <div data-v-2595c7a6="" style="margin-top: 48px !important; font-family: Rubik, sans-serif !important;
" class="d-flex mt-12"><span style="font-size: 20px; font-weight: bold; color: #466eb5 !important;">Hola ${name},</span>
<br>
<span style=" font-size: 16px !important; color: black;
  line-height: 1.8; font-family: Rubik, sans-serif !important;
" data-v-2595c7a6="">Gràcies per la confiança. Et volem fer saber que la teva comanda ha sigut satisfactòria i que s'està preparant per a l'enviament. Si tens algun dubte o problema contacte amb nosaltres
<a data-v-2595c7a6=""
style=" font-family: Rubik, sans-serif !important;"
href="mailto:contacte@examenselectivitat.cat" target="_top"> contacte@examenselectivitat.cat</a>. <br>
</span><div data-v-2595c7a6="" class="mt-9 pa-10 rounded-lg shadow-small mx-auto v-sheet theme--light" style="max-width: 400px !important; width: 100% !important;  margin-right: auto !important; box-shadow: 0 2px 4px rgba(0, 0, 0, .1), 0 8px 16px rgba(0, 0, 0, .1) !important;
  margin-left: auto !important;  margin-top: 40px; padding: 48px; border-radius: 8px; border-top: 4px solid rgb(70, 110, 181)">
  <div data-v-2595c7a6="" style="  align-items: flex-end !important; font-family: Rubik, sans-serif !important;
" class="d-flex align-end; margin-top: 12px">
  <span data-v-2595c7a6="" style="font-weight: bold; width: 100%;  font-family: Rubik, sans-serif !important;
  font-size: 16px !important; color: black;" class="font-weight-medium col-4 px-0">La teva comanda serà enviada a:</span> <br>
  <span data-v-2595c7a6="" style="margin-left: 8px; font-size: 16px !important; color: #3f3f3f;
  flex: 0 0 66.6666666667%; font-family: Rubik, sans-serif !important;
  color: #455a64 !important;
  caret-color: #455a64 !important;
  max-width: 66.6666666667%; padding-left: 14px; padding-right: 0" class="ml-2 grey--text text--darken-2 col-8 px-0">${address}</span></div>
  <div data-v-2595c7a6="" style="display: flex;  font-family: Rubik, sans-serif !important;  align-items: flex-end !important; margin-top: 12px
" class="d-flex align-end">
  <span data-v-2595c7a6="" style="font-weight: bold;  font-family: Rubik, sans-serif !important; font-size: 16px !important; color: #3f3f3f;
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%; padding-right: 0; padding-left: 0" class="font-weight-medium col-4 px-0">El teu enviament:</span> <br>
  <span style=" font-family: Rubik, sans-serif !important;
  color: #455a64 !important;
  caret-color: #455a64 !important;
  font-size: 16px !important; padding-left: 14px; padding-right: 0" class="ml-2 grey--text text--darken-2 col-8 px-0">${shipping}</span>
  </div>

  <div data-v-2595c7a6="" style="display: flex;   font-family: Rubik, sans-serif !important; align-items: flex-start !important; margin-top: 12px;
" class="d-flex align-end">

  <span data-v-2595c7a6="" style="font-weight: bold;  font-family: Rubik, sans-serif !important; font-size: 16px !important; color: #3f3f3f;
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%; padding-right: 0; padding-left: 0" class="font-weight-medium col-4 px-0">Seguiment de la comanda:</span> <br>
  <span style=" font-family: Rubik, sans-serif !important;
  color: #455a64 !important;
  caret-color: #455a64 !important;
  font-size: 16px !important; padding-left: 14px; padding-right: 0"
   class="ml-2 grey--text text--darken-2 col-8 px-0">Quan sigui entregat al servei de correus, un correu us serà enviat amb el seguiment de la comanda</span>
  </div>
  </div><br></div></div>`
